import {overrideCheckFunction} from '$c3base/check-mobile-nav.js';
import {maxWidth} from '$c3template/frontend/utils.js';

let navBreakpoint = 0;

export function overrideMobileNav(headerPadding) {
    overrideCheckFunction(function checkMobileNav() {
        const pageWidth = $('html').width();
        const header = $('body > header');
        const nav = $('body > header nav');
        const navList = nav.find('> ul');
        const sides = $('body > header .page-header-side');

        let navWidth = 0;
        nav.find('.level-1 > li').each(function () {
            navWidth += $(this).width() + parseInt(window.getComputedStyle(navList[0]).getPropertyValue('gap'));
        });

        const sidesWidth = maxWidth(sides) * 2;
        const combinedWidth = navWidth + sidesWidth + headerPadding;

        if ((pageWidth < combinedWidth && navBreakpoint === 0)
            || (pageWidth < combinedWidth && navBreakpoint < combinedWidth)) {
            navBreakpoint = combinedWidth;
        }

        if (navBreakpoint !== 0 && pageWidth <= navBreakpoint) {
            header.addClass('header-mobile');

            const center = header.find('.page-header-center');

            center.css('transition', 'none');
            setTimeout(() => {
                center.css('transition', '');
            }, 1);
        } else {
            header.removeClass('header-mobile');
        }
    });
}

